<template>
	<div class="o-wrap">
		<div class="head">
			<el-page-header @back="$router.go(-1)" content="全部消息"></el-page-header>
		</div>
		<div class="body">
			<div class="titleA">
				<span class="title">{{title}}</span>
				<span class="time">{{time}}</span>
			</div>
			<div class="line"></div>
			<div class="cont" v-html="$data.content">
				{{content}}
			</div>
		</div>
	</div>
</template>

<script>
	import Store from '../../store/index.js'
	import Axios from 'axios'
	export default {
		data() {
			return {
				title: '',
				time: '',
				content: '',
			}
		},
		created() {
			Axios.post(`${Store.state.BASE_URL}/getSystemInfo`, this.$qs.stringify({
				id: this.$route.query.id
			})).then(data => {
				let dataA = data.data.data
				this.title = dataA.title;
				this.time = dataA.time;
				this.content = dataA.content;
				
			})
		}

	}
</script>

<style scoped>
	.head{
		height: 50px;
		line-height: 50px;
	}
	.body {
		padding: 18px;
		/* display: flex;
		justify-content: center; */
		border: 1px solid #DCDFE6;
	}
	.titleA {
		font-size: 20px;
		text-align: center;
		height: 80px;
		display: flex;
		flex-flow: column;
		justify-content: center;
	}
	.titleA span {
		padding: 5px 0;
	}
	.titleA span:nth-child(2) {
		font-size: 14px;
		color: #999;
	}
	.line {
		width: 100%;
		border-bottom: 1px solid #DCDFE6;
		margin-bottom: 10px;
	}
	.cont {
		padding: 10px 20px;
	}
</style>
